@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__input-container input {
  @apply text-xl shadow-md focus:outline-none focus:ring-2 focus:ring-yellow-500 block w-full h-14 p-4 rounded-lg
}

.react-datepicker__input-container input::placeholder {
  @apply text-gray-500
}


.react-datepicker-wrapper {
  @apply w-full
}

.react-datepicker__input-container {
  @apply w-full
}

.react-datepicker-popper {
  @apply z-40 w-full text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded
}

.react-datepicker__month-container {
  @apply flex flex-col
}

.react-datepicker__month {
  @apply flex flex-col
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-700
}

.react-datepicker__week {
  @apply flex justify-around
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full
}

.react-datepicker__navigation {
  @apply absolute top-2
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded
}

.react-datepicker__day--disabled {
  @apply bg-white cursor-not-allowed opacity-40 hover:bg-transparent !important
}

.react-datepicker__day--outside-month {
  @apply text-gray-300
}

.react-datepicker__day {
  @apply bg-green-100 cursor-pointer
}

.react-datepicker__day--in-range {
  @apply bg-gray-200
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}

input {
  @apply appearance-none cursor-pointer;
}

input[type="radio"]:checked{
  @apply bg-wsd
}

input[type="checkbox"]:checked{
  @apply bg-wsd
}

.ql-align-right {
	@apply flex justify-end
}

.ql-align-center {
	@apply flex justify-center
}

.ql-align-left {
	@apply flex justify-start
}

.ql-size-large {
  @apply text-xl
}

.ql-size-normal {
  @apply text-lg
}

.ql-size-huge {
  @apply text-2xl
}

.ql-size-small {
  @apply text-sm
}

#headlessui-portal-root > div > button {
  @apply hidden
}

.react-datepicker__aria-live {
  display: none;
}